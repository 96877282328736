<template>
  <b-overlay :show="isLoading">
    <b-row>
      <b-col>
        <h1>Basic Info</h1>
      </b-col>
      <b-col cols="3" align-self="center">
        <b-alert
          align-v="center"
          variant="warning"
          :show="saveMessage !== ''"
          >{{ this.saveMessage }}</b-alert
        >
      </b-col>
      <b-col cols="auto">
        <!-- Button -->
        <b-button :disabled="saveDisabled" variant="primary" @click="doSave">
          {{ $t("save") }}
        </b-button>
      </b-col>
    </b-row>
    <b-form-group :label="$t('application-name')">
      <b-form-input
        name="application-name"
        v-validate="'required'"
        v-model="config.name"
      ></b-form-input>
      <div v-if="submitted && errors.has('application-name')" class="alert-danger">
        {{ errors.first("application-name") }}
      </div>
    </b-form-group>
    <b-row>
      <b-col>
        <b-form-group label-size="lg" :label="$t('favicon')">
          <ImageUpload
            :url="config.favicon"
            ref="image"
            :title="$t('favicon')"
            @updateImage="updateFavicon"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-overlay>
</template>
<script>
import ImageUpload from "@/components/admin/ImageUpload.vue";

export default {
  components: {
    ImageUpload,
  },
  data() {
    return {
      isLoading: false,
      saveDisabled: true,
      saveMessage: "",
      submitted: false
    };
  },
  async created() {
    await this.$store.dispatch("Config/fetchAll");
    this.saveDisabled = true;
    this.saveMessage = "";
  },
  watch: {
    config: {
      handler() {
        this.saveMessage = "You have unsaved changes!";
        this.saveDisabled = false;
      },
      deep: true,
    },
  },
  computed: {
    config() {
      return this.$store.state.Config.all;
    },
  },
  methods: {
    async doSave() {
      this.submitted = true
      this.$validator.validateAll().then(async (isValid) => {
        if (!isValid) {
          return;
        }
        this.isLoading = true;

        this.$refs.image.uploadImage();
        await this.$store.dispatch("Config/update", this.config);
        await this.$store.dispatch("Config/fetchAll");
        this.$root.$bvToast.toast("Basic info saved!", {
          variant: "success",
        });
        this.saveDisabled = true;
        this.submitted = false
        this.saveMessage = "";
        this.isLoading = false;
      });
    },
    updateFavicon(newUrl) {
      this.config.favicon = newUrl;
    },
  },
};
</script>
<style lang="scss"></style>
